import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Test Week`}</em></p>
    <p>{`30 minutes to establish a max:`}</p>
    <p>{`Full Clean`}</p>
    <p>{`Front Squat`}</p>
    <p>{`Test 1: Max Pushups, 1 attempt (No hand release, chest touches floor and
elbows lock out each rep)`}</p>
    <p>{`Test 2: Max Ring Rows, 1 attempt (Chest to rings, full extension of arms
at bottom. Lying under dead hanging, hip height rings)`}</p>
    <p><strong parentName="p">{`*`}{`This Friday the 6pm class will be cancelled to set up for Saturday’s
 Granite Games Winter Throwdown.  If you can help us set up please let
us know.  The more the merrier!  `}</strong></p>
    <p><strong parentName="p">{`*`}{`The gym will be closed Saturday for the Winter Throwdown but if you
aren’t competing or volunteering come out and cheer on the competitors!
 The first heat starts at 9:00am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      